import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				HarborView Realty
			</title>
			<meta name={"description"} content={"Odblokuj swój wymarzony dom dzięki wiedzy i uczciwości"} />
			<meta property={"og:title"} content={"HarborView Realty"} />
			<meta property={"og:description"} content={"Odblokuj swój wymarzony dom dzięki wiedzy i uczciwości"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-3%20%281%29.jpg?v=2024-06-14T08:38:33.655Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-3%20%281%29.jpg?v=2024-06-14T08:38:33.655Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-3%20%281%29.jpg?v=2024-06-14T08:38:33.655Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-3%20%281%29.jpg?v=2024-06-14T08:38:33.655Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-3%20%281%29.jpg?v=2024-06-14T08:38:33.655Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-3%20%281%29.jpg?v=2024-06-14T08:38:33.655Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-3%20%281%29.jpg?v=2024-06-14T08:38:33.655Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 0 0" sm-padding="60px 0 60px 0">
			<Box
				padding="80px 72px 80px 72px"
				background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/Image%20%289%29.png?v=2021-08-26T14:56:08.732Z) 0% 0% /cover no-repeat scroll padding-box"
				border-radius="24px"
				margin="0px 0px 64px 0px"
				sm-padding="60px 36px 60px 36px"
			>
				<Box
					width="60%"
					display="flex"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--dark"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Nasza wiedza
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--greyD3" md-text-align="center">
						W HarborView Realty nasza wiedza opiera się na dziesięcioleciach doświadczenia, podczas których udoskonaliliśmy nasze podejście, aby spełniać, a nawet przekraczać oczekiwania naszych klientów. Nasz zespół składa się z weteranów branży, którzy wnoszą głębokie zrozumienie niuansów rynku nieruchomości. Ta wiedza pozwala nam zapewnić dostosowane porady, które są zgodne z Twoimi konkretnymi celami w zakresie nieruchomości, zapewniając, że niezależnie od tego, czy kupujesz, sprzedajesz czy inwestujesz, masz do dyspozycji najlepsze informacje i strategie. Jesteśmy dumni z naszej zdolności do poruszania się po trendach rynkowych, dostarczania spersonalizowanych usług i osiągania optymalnych wyników dla naszych klientów.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Wprowadzenie do naszych usług
				</Text>
				<Text font="--lead" color="--darkL2" max-width="600px">
					Nasze usługi mają na celu pokrycie każdego aspektu Twojej podróży po nieruchomościach, niezależnie od tego, czy kupujesz, sprzedajesz, czy inwestujesz. Dzięki zespołowi oddanych profesjonalistów i bogatym zasobom zapewniamy płynną i pomyślną obsługę dostosowaną do Twoich konkretnych potrzeb.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-1.jpg?v=2024-06-14T08:38:33.659Z"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
						srcSet="https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-1.jpg?v=2024-06-14T08%3A38%3A33.659Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-1.jpg?v=2024-06-14T08%3A38%3A33.659Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-1.jpg?v=2024-06-14T08%3A38%3A33.659Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-1.jpg?v=2024-06-14T08%3A38%3A33.659Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-1.jpg?v=2024-06-14T08%3A38%3A33.659Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-1.jpg?v=2024-06-14T08%3A38%3A33.659Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-1.jpg?v=2024-06-14T08%3A38%3A33.659Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Text margin="0px 0px 16px 0px" font="--lead" color="--darkL2" opacity="0.5">
						Odkryj swoją idealną nieruchomość dzięki naszym specjalistycznym usługom zakupu:
					</Text>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Kompleksowa obsługa kupujących
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Nabywcy domów: Przeglądaj szeroką gamę nieruchomości, od nowoczesnych apartamentów po rozległe osiedla, dostosowanych do Twojego stylu życia i budżetu.
Inwestorzy: Uzyskaj dostęp do lukratywnych możliwości inwestycyjnych i szczegółowych analiz rynkowych, które obiecują dobre zyski.
Kupujący po raz pierwszy: Skorzystaj z naszych szczegółowych wskazówek, dzięki którym Twoje pierwsze wejście na rynek nieruchomości będzie możliwie bezproblemowe.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/666ac377f0cb1d0025f631cb/images/1-1.jpg?v=2024-06-14T08:38:33.660Z"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
						object-position="70% 50%"
						srcSet="https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/1-1.jpg?v=2024-06-14T08%3A38%3A33.660Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/1-1.jpg?v=2024-06-14T08%3A38%3A33.660Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/1-1.jpg?v=2024-06-14T08%3A38%3A33.660Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/1-1.jpg?v=2024-06-14T08%3A38%3A33.660Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/1-1.jpg?v=2024-06-14T08%3A38%3A33.660Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/1-1.jpg?v=2024-06-14T08%3A38%3A33.660Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/1-1.jpg?v=2024-06-14T08%3A38%3A33.660Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Text margin="0px 0px 16px 0px" font="--lead" color="--darkL2" opacity="0.5">
						Osiągnij najlepszy wynik w procesie sprzedaży dzięki naszemu strategicznemu podejściu:
					</Text>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Usługi sprzedaży dynamicznej
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Analiza rynku: Otrzymuj dokładne i kompleksowe informacje rynkowe, aby pozycjonować swoją nieruchomość na tle konkurencji.
Widoczność i marketing: Skorzystaj z naszych solidnych strategii marketingowych, które zapewnią, że Twoja nieruchomość dotrze do właściwych odbiorców.
Eksperci negocjatorzy: Zaufaj naszym umiejętnościom negocjacyjnym, aby zapewnić najlepszą ofertę, równoważąc szybkość i cenę.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/666ac377f0cb1d0025f631cb/images/1-3.jpg?v=2024-06-14T08:38:33.650Z"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
						srcSet="https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/1-3.jpg?v=2024-06-14T08%3A38%3A33.650Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/1-3.jpg?v=2024-06-14T08%3A38%3A33.650Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/1-3.jpg?v=2024-06-14T08%3A38%3A33.650Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/1-3.jpg?v=2024-06-14T08%3A38%3A33.650Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/1-3.jpg?v=2024-06-14T08%3A38%3A33.650Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/1-3.jpg?v=2024-06-14T08%3A38%3A33.650Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/1-3.jpg?v=2024-06-14T08%3A38%3A33.650Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Text margin="0px 0px 16px 0px" font="--lead" color="--darkL2" opacity="0.5">
						Zmaksymalizuj swoje zyski dzięki naszej specjalistycznej wiedzy inwestycyjnej:
					</Text>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Dostosowane usługi inwestycyjne
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Strategie wejścia na rynek: Porady ekspertów dotyczące wejścia na rynek nieruchomości lub ekspansji na nim, dostosowane do Twoich perspektyw finansowych.
Zarządzanie portfelem: Indywidualne rozwiązania do zarządzania, które są zgodne z Twoimi długoterminowymi celami inwestycyjnymi.
Zarządzanie ryzykiem: Dogłębna ocena ryzyka w celu strategicznego minimalizowania potencjalnych wad.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-2.jpg?v=2024-06-14T08:38:33.666Z"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
						srcSet="https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-2.jpg?v=2024-06-14T08%3A38%3A33.666Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-2.jpg?v=2024-06-14T08%3A38%3A33.666Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-2.jpg?v=2024-06-14T08%3A38%3A33.666Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-2.jpg?v=2024-06-14T08%3A38%3A33.666Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-2.jpg?v=2024-06-14T08%3A38%3A33.666Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-2.jpg?v=2024-06-14T08%3A38%3A33.666Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666ac377f0cb1d0025f631cb/images/2-2.jpg?v=2024-06-14T08%3A38%3A33.666Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Text margin="0px 0px 16px 0px" font="--lead" color="--darkL2" opacity="0.5">
						Łatwe zarządzanie nieruchomościami, aby zmaksymalizować potencjał Twojej inwestycji:
					</Text>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Usługi zarządzania nieruchomościami
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Pozyskiwanie najemców: Usprawnione procesy umożliwiające szybkie znalezienie niezawodnych najemców.
Utrzymanie i utrzymanie: Kompleksowe zarządzanie utrzymaniem nieruchomości w celu zachowania jej wartości.
Obsługa finansowa: Efektywna obsługa wszystkich finansów związanych z nieruchomościami, w tym pobieranie czynszów i zarządzanie wydatkami.
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/666ac377f0cb1d0025f631cb/images/1-4.jpg?v=2024-06-14T08:38:33.649Z) center/cover"
			padding="140px 0 140px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
						Odkryj więcej dzięki HarborView
					</Text>
					<Text as="h1" font="--headline3" md-font="--headline2" margin="10px 0">
						Nasze wymienione usługi to dopiero początek. HarborView Realty oferuje różnorodną gamę dostosowanych usług zaprojektowanych tak, aby zaspokoić zróżnicowane potrzeby każdego klienta. Zanurz się głębiej w to, co możemy zaoferować, kontaktując się. Niezależnie od tego, czy wchodzisz na rynek po raz pierwszy, czy chcesz poszerzyć swoje portfolio, posiadamy wiedzę specjalistyczną, która pomoże Ci na każdym etapie Twojej podróży na rynku nieruchomości.
					</Text>
				</StackItem>
			</Stack>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac377f0cb1d0025f631c9"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});